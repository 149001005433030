import { Typography } from "../UI/Typography/Typography";
import BannerSection from "./BannerSection";
import JoinClub from "./JoinClub";
import Links from "./Links";
import MobileAppImage from "../../assets/footer/mobile_app.png";

const FooterComponents = () => {
  return (
    <div className="">
      <div className="flex flex-col items-center m-auto py-10 bg-beige-prim">
        <h1 className="text-red-prim font-black uppercase text-2xl md:text-6xl">
          Make More Money 
        </h1>
        <h1 className="text-red-prim font-black uppercase text-xl md:text-6xl">
          With
        </h1>
        <h1 className="text-red-prim font-black uppercase text-2xl md:text-6xl">
          The Moneycessity App
        </h1>
        <h1 className="text-white hover-red bg-black px-6 py-4 hover:opacity-80 my-5 font-bold uppercase">
          Coming Soon . .
        </h1>
        <img src={MobileAppImage} className="w-8/12 md:w-6/12" />
      </div>
      <div className="bg-red-prim p-5 pt-10 md:px-[5vw]">
        <section className="flex flex-col gap-5 md:flex-row md:justify-between">
          <JoinClub />
          <Links />
        </section>
        <section className="mt-10">
          <BannerSection />
          <div className="flex flex-col md:flex-row md:justify-between">
            <p className="text-white font-light text-start text-base md:text-2xl">
              Moneycessity © 2024 All Rights Reserved
            </p>
            <a
              className="text-white font-light underline underline-offset-1 text-start text-base md:text-2xl"
              href="/disclaimer"
            >
              Privacy, Terms, & Disclaimer
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FooterComponents;
