import { Typography } from "../components/UI/Typography";

export const DisclaimerPage = () => {
  return (
    <div className="bg-beige-prim pt-40 text-left px-4 md:px-20 flex flex-col gap-5">
      <Typography variant="title">
        Privacy, Terms and Conditions, and Disclaimer
      </Typography>
      <Typography>
        By visiting{" "}
        <a href="https://moneycessity.com">https://moneycessity.com</a>{" "}
        (“website”), subscribing to the Moneycessity newsletter, and using the
        Moneycessity mobile app, you are consenting to our privacy policy.
      </Typography>
      <div className="p-5"></div>
      <Typography variant="h1">Privacy Policy</Typography>
      <Typography>
        <em>
          NOTICE: Please read the Privacy Policy set forth below carefully, as
          it is designed to provide important information on how and why we
          collect, use, store and share your personal information.&nbsp; It also
          outlines the rights you can exercise regarding your personal
          information and how you can contact us if you have any questions or
          complaints.&nbsp;&nbsp;
        </em>
      </Typography>
      <Typography>
        <em>
          The Privacy Policy set forth below is legally binding.&nbsp; By
          visiting, viewing or using this website and/or by using any program,
          product, course or service from us, you agree to be bound by this
          Privacy Policy.
        </em>
      </Typography>
      <Typography>
        Your privacy is important to Moneycessity,{" "}
        <a href="https://moneycessity.com">https://moneycessity.com</a>{" "}
        (“website”), which is operated by Pome LLC<em>) </em>(“Company”, “we”,
        “us”, or “our”).&nbsp;&nbsp;
      </Typography>
      <Typography>
        We use the term “personal information” to refer to data we collect that
        may, directly or indirectly, identify, describe, relate to or be
        associated with you.&nbsp; This privacy policy (“Privacy Policy”)
        applies to personal information we collect when you interact with us
        through different means, including by visiting and using our website.
        &nbsp; The term “you” refers to any visitor, viewer or user of the
        website and/or any user of any Product.&nbsp; Please note that we cannot
        control the privacy practices of websites and services that we do not
        own.
      </Typography>
      <Typography>
        Please read the entire Privacy Policy before you visit or use the
        website or perform any Actions (as defined below).&nbsp; By visiting the
        website or performing any Actions (as defined below), you consent to the
        terms of this Privacy Policy.&nbsp; This Privacy Policy was created with
        the help of Plug and Law and Privacy Policy Solutions.
      </Typography>
      <Typography>
        <strong>INFORMATION WE COLLECT AND HOW WE COLLECT IT</strong>
      </Typography>
      <Typography>
        The following describes the categories of personal information we
        collect and how we collect such information.
      </Typography>
      <Typography>
        <strong>Information You Provide. </strong>We collect information you
        provide to us directly.&nbsp; This includes information you provide when
        you (i) receive any free or purchase any paid programs, products,
        courses or services from us (each, a “Product”), (ii) sign up to receive
        any emails, (iii) comment on any posts or otherwise communicate with us
        on any social media platform, (iv) register for presentations or
        classes, (v) fill out any forms, (vi) access public or private
        membership groups, including those hosted via a third-party platform
        (i.e., Facebook), (vii) sign up to become our affiliate partner, (viii)
        respond to any survey, (ix) participate in any contest or sweepstakes,
        or (x) contact us through any other means, including via an online form,
        phone call, or email (collectively, the “Actions”).&nbsp;&nbsp;
      </Typography>
      <Typography>
        Examples of data we may receive include your first name, last name,
        telephone number, email address, shipping address, billing address,
        physical address (such as your address, state, province, ZIP/postal code
        and city), date of birth, gender, account name, billing information
        (such as your credit card number), financial information, Social
        Security Number, Tax Identification Number, Employer Identification
        Number, PayPal address, social media information, and other information
        you provide to us through survey responses, feedback, reviews and other
        means of communication.
      </Typography>
      <Typography>
        <strong>Information Collected Automatically.&nbsp; </strong>We collect
        some data automatically when you visit or use our website or open or
        respond to our emails.&nbsp; For example, we may automatically collect
        information when you open or respond to our emails, make a choice with
        respect to communications we send to you, visit any page that displays
        our content, provide information to our service providers or purchase or
        return a Product.
      </Typography>
      <Typography>
        <strong>Information from Third Party Sites.</strong>&nbsp; We collect
        some data when you connect with us, comment on or like our posts or
        otherwise interact with us on any social media platform, or when you
        access public or private membership groups hosted on a third party
        platform (i.e., Facebook).&nbsp; Examples of data we may receive include
        your profile information, profile picture, social media information,
        social media handles or nicknames, name, purchase history, email
        address, device identifiers and demographic information.
      </Typography>
      <Typography>
        <strong>
          Information from Internet or Other Electronic Network Activity.&nbsp;{" "}
        </strong>
        We automatically collect some data about your computer or mobile device
        when you access our website.&nbsp; Examples of data we may receive
        include your Internet Protocol (“IP”) address, browser type, browser
        version, cookies from your browser, unique device identifiers, web
        browser software (i.e., Google Chrome), information about the referring
        website, the date, time and length of your visit, including the specific
        pages you visit, information on how you interact with the website,
        Products and tools and other diagnostic data.&nbsp; Examples of
        additional data we may receive when you access our website through a
        mobile device include the type of mobile device you are using, the
        unique mobile device ID, your mobile operating system, web browser
        software on mobile, unique device identifiers and other diagnostic data.
      </Typography>
      <Typography>
        <strong>PURPOSES FOR COLLECTING INFORMATION</strong>
      </Typography>
      <Typography>
        We use your information for business and commercial purposes.&nbsp; For
        example, we may use your information to:
      </Typography>
      <Typography>
        <li>Register you for a course, presentation or class.</li>
        <li>
          Grant you access to a public or private membership group or other
          account, and maintain and service your profiles for such accounts.
        </li>
        <li>
          Validate and authenticate your profile when logging into a public or
          private membership group or other account or when purchasing a
          Product.
        </li>
        <li>Analyze interactions with you to improve quality.</li>
        <li>Identify your product preferences and shopping preferences.</li>
        <li>Secure our website and data.</li>
        <li>Create Products that you are interested in.</li>
        <li>
          Ship or otherwise deliver, process payment for, communicate about, and
          track orders of any Products.
        </li>
        <li>
          Suggest Products you may like based on past purchases and otherwise
          personalize your experience with the website.
        </li>
        <li>
          Provide promotional and marketing communications and information if
          you elect to receive it, including email marketing.
        </li>
        <li>
          Improve the design, functionality and ease-of-use of our website and
          Products.
        </li>
        <li>
          Respond to any inquiries, reviews or other feedback you submit to us.
        </li>
        <li>Provide customer service.</li>
        <li>Conduct research to improve our business processes.</li>
        <li>Administer affiliate programs.</li>
        <li>Administer contests, sweepstakes, surveys or promotions.</li>
        <li>
          Administer any business needs related to your purchase of any
          Products.
        </li>
        <li>
          Detect security incidents and protect against, stop, resolve and
          prevent any fraud and fraudulent transactions and any malicious,
          deceptive or illegal activity.
        </li>
        <li>Comply with all applicable law.</li>
        <li>
          Respond to legal and regulatory inquiries and assist law enforcement.
        </li>
      </Typography>
      <Typography>
        <strong>THIRD PARTIES WE SHARE INFORMATION WITH</strong>
      </Typography>
      <Typography>
        The following is a list of third parties that we may share your
        information with or for.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>Affiliate Partners.</strong>&nbsp; We may share your information
        with affiliate partners to generate traffic or leads or for other
        business purposes.
      </Typography>
      <Typography>
        <strong>Analytics Providers.&nbsp; </strong>We may share your
        information with analytics providers.
      </Typography>
      <Typography>
        <strong>Business Transfers.</strong>&nbsp; If we and/or our website are
        acquired by a third party as a result of a transfer, sale, merger,
        acquisition, reorganization, liquidation, consolidation, merger or sale
        of some or all of our Company and/or our website, your personal
        information may be a transferred asset.&nbsp; We may also share personal
        information to prospective purchasers to diligence the proposed
        transaction.
      </Typography>
      <Typography>
        <strong>Cloud Service Providers.</strong>&nbsp; We may share your
        information with cloud service providers.
      </Typography>
      <Typography>
        <strong>Customer Analysis Providers.</strong>&nbsp; We may share your
        information with customer analysis providers, such as those used to
        analyze visitors clicks and navigation around the website.
      </Typography>
      <Typography>
        <strong>Customer Service Providers.</strong>&nbsp; We may share your
        information with customer service providers.
      </Typography>
      <Typography>
        <strong>Delivery Partners.</strong>&nbsp; We may share your information
        with delivery partners, such as those we use to ship or otherwise
        deliver any Products.
      </Typography>
      <Typography>
        <strong>Email Marketing and Advertising Providers.</strong>&nbsp; We may
        share your information with marketing and advertising providers,
        including email marketing and campaign providers, marketing software
        providers, direct mail providers, marketing analytics providers and
        sales funnel providers.<strong>&nbsp; </strong>We may share your
        information with email marketing service providers, in order to send you
        emails, newsletters, promotional materials, marketing materials or other
        information.&nbsp;
      </Typography>
      <Typography>
        <strong>Fraud Prevention Partners.</strong>&nbsp; We may share your
        information with fraud prevention partners.
      </Typography>
      <Typography>
        <strong>Fulfillment Partners.</strong>&nbsp; We may share your
        information with fulfillment partners, such as those we use to fulfill
        any Products.
      </Typography>
      <Typography>
        <strong>Government Agencies.&nbsp; </strong>We may share your
        information with government agencies, courts, regulators and law
        enforcement in the event we are required to comply with applicable laws
        and regulations or a legally binding process, or in response to
        subpoenas, warrants, government inquiries or investigations, and court
        orders.&nbsp; We may also share your information (i) to establish,
        exercise, protect or enforce our legal rights and the legal rights of
        our agents, employees and affiliates, (ii) to defend against a legal
        claim, (iii) to protect you, us or third parties against injury,
        interference, fraud or harm or (iv) to take action related to violations
        of our policies, including this Privacy Policy and our Terms &amp;
        Conditions, or potentially illegal activities.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>Other Service Providers.</strong>&nbsp; We may share your
        information with service providers.
      </Typography>
      <Typography>
        <strong>Payment Processors.</strong>&nbsp; We may share your information
        with payment processors, such as those we use to collect and process
        payment for any Products you purchase.
      </Typography>
      <Typography>
        <strong>Public Forum.&nbsp; </strong>Our website may allow you to leave
        a post, comment or review on the website.&nbsp; If you choose to submit
        that information on a public forum, that information will be available
        to the public and we may elect to share your post, comment or review
        outside of the website.
      </Typography>
      <Typography>
        <strong>Social Media Platforms.</strong>&nbsp; We may share your
        information with social media platforms (i.e., Facebook).&nbsp; Their
        use of your information is not governed by this Privacy Policy.
      </Typography>
      <Typography>
        <strong>Technology Service Providers.</strong>&nbsp; We may share your
        information with technology service providers.
      </Typography>
      <Typography>
        <strong>Third Parties.</strong>&nbsp; We may share your information with
        third parties whom we have contractual relationships with, such as
        auditors, consultants, lawyers, and other professionals who rely on the
        data to provide us with professional services.
      </Typography>
      <Typography>
        <strong>Third Party Partners.</strong>&nbsp; We may share your
        information with third parties we have partnered with to jointly create
        or offer a product, service or joint promotion.
      </Typography>
      <Typography>
        <strong>WE DO NOT SELL YOUR PERSONAL INFORMATION</strong>
      </Typography>
      <Typography>
        We do not sell, rent or trade your personal information to any third
        parties, as we value your privacy.&nbsp; We also do not “sell” your
        personal information as defined by the California Consumer Privacy Act.
      </Typography>
      <Typography>
        <strong>COOKIES AND OTHER TRACKING TECHNOLOGIES</strong>
      </Typography>
      <Typography>
        We may collect and access, and may permit our business partners and
        third party service providers, such as advertising companies, to collect
        and access your Internet Protocol (IP) address, browsing metadata and
        other numerical identifiers, such as your browser type, version and
        operating system (collectively, the “Browsing Information”).&nbsp; We
        may also use, place, collect and store, or allow our third party service
        providers to use, place, collect and store, cookies, web beacons,
        remarketing pixel tags, or other similar tracking
        technologies.&nbsp;&nbsp;
      </Typography>
      <Typography>
        We, our business partners and third party service providers may use this
        information and these technologies to, among other things, improve and
        personalize your user experience, understand how you use the website,
        provide tailored ads, analyze trends, data, and website performance,
        administer the website, identify and track you when you use different
        devices, determine if you are logged onto the website, provide security
        and provide a range of features, customization and
        functionality.&nbsp;&nbsp;
      </Typography>
      <Typography>
        By using the website and not opting out of cookies, you consent to the
        use of Browsing Information, cookies and other tracking technologies as
        described in this Privacy Policy.&nbsp; Note that we have no control
        over these third party service providers and their use of such tracking
        technologies.&nbsp; We cannot and do not control the privacy policies
        and practices of any third party service providers.&nbsp; We encourage
        you to visit their websites directly to learn about their privacy
        policies.
      </Typography>
      <Typography>
        <strong>YOUR CHOICES</strong>
      </Typography>
      <Typography>
        It is important to us that you understand your choices regarding your
        personal information.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>Opting Out of/Blocking Cookies.</strong>&nbsp; Most browsers
        accept cookies by default.&nbsp; However, most browsers will allow you
        to prevent accepting new cookies, disable cookies, and/or receive a
        notification when you receive new cookies.&nbsp; If your browser has
        such functionalities, information on how you can change your cookie
        settings can typically be found in the help section of the browser
        toolbar.&nbsp; Please note that if you do disable cookies, this may have
        an impact on or interfere with your user experience, including your
        ability to use or make purchases from the website, or receive
        personalized content.
      </Typography>
      <Typography>
        <strong>Managing “Do Not Track”.&nbsp; </strong>As required by the
        California Online Privacy Protection Act (CalOPPA), we would like you to
        know that our systems are currently unable to recognize browser “Do No
        Track” signals.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>Use of Personal Information.&nbsp; </strong>You can request that
        we delete your personal information at any time by contacting us using
        the contact details provided below, subject to certain exceptions.&nbsp;
        You can also (i) request to see your personal information that we have
        available on you, (ii) withdraw consent for our use of your personal
        information, (iii) review and request we rectify, change or modify your
        personal information, (iv) restrict or limit the processing of your
        personal information, (v) cancel the processing of your personal
        information and (vi) request your personal information and transfer it
        to another controller without any impediments on our part by contacting
        us using the contact details provided below.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>Unsubscribing from Email Marketing.</strong>&nbsp; When you sign
        up to receive emails from us, you agree to receive email communications
        from us, which may include newsletters and promotional emails.&nbsp; If
        you receive any email marketing from us, you can opt out at any time by
        clicking the “unsubscribe” link contained in each email.&nbsp; Please
        note that unsubscribing from email marketing does not necessarily
        unsubscribe you from other emails we may send, such as emails about any
        Products you purchase.&nbsp;
      </Typography>
      <Typography>
        <strong>Other Opt-Out Options.&nbsp; </strong>If we send you physical
        mailings or short message service (SMS) messages or contact you via
        telephone, you can opt-out by contacting us using the contact details
        provided below.
      </Typography>
      <Typography>
        <strong>DATA RETENTION</strong>
      </Typography>
      <Typography>
        We retain personal information as long as it is needed to conduct and
        operate our business or until you ask us to delete your personal
        information by contacting us using the contact details provided
        below.&nbsp; Please note that we cannot control the data retention
        policies of third parties.
      </Typography>
      <Typography>
        <strong>THIRD PARTY LINKS</strong>
      </Typography>
      <Typography>
        For your convenience, we provide links to third party websites on our
        website, such as links to third party social media platforms.&nbsp; If
        you click on a link to a third party website, you will be directed to a
        third party website.&nbsp; We cannot and do not control the privacy
        policies, content and practices of the website owners and operators of
        any of the third party websites that we link to.&nbsp; We encourage you
        to visit their websites directly to learn about their privacy policies.
      </Typography>
      <Typography>
        <strong>HOW WE PROTECT YOUR PERSONAL INFORMATION</strong>
      </Typography>
      <Typography>
        We care about the security of your personal information, so we maintain
        reasonable and appropriate physical, technical and procedural safeguards
        to help keep it safe.&nbsp; While we take reasonable steps to protect
        your personal information, no method of transmission over the internet
        or other network can be 100% secure, therefore we cannot and do not
        guarantee that personal information you transmit will remain secure from
        misuse or interception in all circumstances.&nbsp; By consenting to this
        Privacy Policy, you acknowledge that we cannot guarantee that your
        personal information will be protected from misuse or interception by
        third parties.
      </Typography>
      <Typography>
        <strong>INTERNATIONAL DATA, TRANSFERS AND PROCESSING</strong>
      </Typography>
      <Typography>
        Our website is intended for individuals located within the United
        States.&nbsp; Please be aware that our website servers and our service
        providers may be located outside of your state, province or
        country.&nbsp; As a result, some of your personal information may be
        collected, used, transferred, maintained, disclosed and stored outside
        of your state, province or country.&nbsp; By using this website, you
        acknowledge and agree that the collection, use, transfer, maintenance,
        disclosure and storage of your personal information, Browsing
        Information and communications related to or arising out of your use of
        this website is governed by the applicable laws in the United
        States.&nbsp; While we have the appropriate safeguards in place, the
        applicable privacy laws in the United States may be less stringent than
        those of your state, province or country.&nbsp;
      </Typography>
      <Typography>
        <strong>
          NOTICE ABOUT THE GENERAL DATA PROTECTION REGULATION (GDPR NOTICE)
        </strong>
      </Typography>
      <Typography>
        The information in this section, under the subheading “Notice About the
        General Data Protection Regulation”, applies to individuals covered by
        the General Data Protection Regulation (“GDPR”).&nbsp; References to
        “you” and “your” in this section refer only to those covered by
        GDPR.&nbsp; GDPR, which took effect on May 25, 2018, provides privacy
        rights for those inside the European Economic Area.&nbsp;&nbsp;
      </Typography>
      <Typography>
        For the purposes of this section, “personal information” refers to any
        information relating to an individual which can be directly or
        indirectly used to identify such individual.&nbsp; Examples include
        first name and last name, email address, identification number,
        information about location, ethnicity, gender, biometric data, web
        cookies, and religious or political beliefs.
      </Typography>
      <Typography>
        <strong>Legal basis for processing information.&nbsp; </strong>We may
        process personal information under the following conditions: (i) we have
        received your consent to process your personal information for one or
        more specific purposes, (ii) processing of your personal information is
        necessary to perform a contract to which you are a party to, or in order
        to take steps at your request prior to entering into a contract, (iii)
        processing of your personal information is necessary to comply with a
        legal obligation we are subject to, (iv) processing of your personal
        information is necessary in order to protect the vital interests of you
        or another natural person, (v) processing of your personal information
        is necessary to perform a task carried out in the public interest or to
        exercise an official authority vested in us; (vi) processing of your
        personal information is necessary for the purposes of the legitimate
        interests pursued by us or a third party, except in certain
        circumstances where the need for the information is overridden by the
        need to protect the subject of the personal information (such as when
        the subject of the personal information is a child).&nbsp;
      </Typography>
      <Typography>
        We are happy to let you know which legal basis applies to the processing
        of your personal information.
      </Typography>
      <Typography>
        You have certain rights you can exercise under the GDPR, including the
        following.&nbsp; Please note that this summary is merely provided for
        your convenience, but we do not warrant the accuracy or exhaustiveness
        of this summary nor should you rely on this as an accurate or exhaustive
        list of your rights.
      </Typography>
      <Typography>
        <li>
          <strong>Right to Access.</strong>&nbsp; You have the right to learn
          whether or not your personal information is being processed. If it is
          being processed, you have the right to access the personal
          information, and to learn certain information about your personal
          information, including: (i) why it is being processed, (ii) the
          categories of personal information we collected, (iii) the recipients
          or categories of recipients to whom we have or will disclose the
          personal information to, (iv) if possible, the amount of time we will
          store the personal information, or if not possible, the criteria we
          use to determine such period and (v) available information about the
          sources for personal information we collected.
        </li>
        <li>
          <strong>Right to Correction.</strong>&nbsp; You have the right to
          correct any inaccurate personal information about yourself.&nbsp; You
          also have the right to complete any incomplete personal information
          collected, including through providing an additional statement.
        </li>
        <li>
          <strong>Right to Be Forgotten. </strong>You have the right to ask us
          to erase your personal information, which we will do without undue
          delay under certain circumstances.&nbsp; Examples may include: (i)
          where the personal information is no longer necessary for the purposes
          for which it was collected, (ii) where you withdraw consent on the
          basis of which we processed your personal information, and there is no
          legal ground for processing such personal information, (iii) where you
          invoke your right to object (described below) and there are no
          overriding grounds for processing such personal information, (iv)
          where your personal information has been unlawfully processed and (v)
          where the personal information has to be erased to comply with a legal
          obligation.
        </li>
        <li>
          <strong>Right to Restrict Processing. </strong>You have the right to
          restrict the processing of your personal information under certain
          circumstances.&nbsp; Examples may include: (i) where you indicate the
          inaccuracy of your personal information, (ii) where the processing is
          unlawful but you would like the processing of your personal
          information to be restricted as opposed to erased, (iii) where we no
          longer need the personal information for processing, but you would
          like it restricted for a legal basis, and (iv) where you invoke your
          right to object (described below).
        </li>
        <li>
          <strong>
            Notification of Recipients of Personal Information.&nbsp;{" "}
          </strong>
          If you exercise your Right to Rectification, Right to Be Forgotten or
          Right to Restrict Processing (each as described above), we will convey
          that to each recipient we have shared your personal information
          with.&nbsp; You have the right to request we provide you with a list
          of all recipients we have notified.
        </li>
        <li>
          <strong>Right to Data Portability. </strong>You have the right to
          request your personal information in a structured, commonly used and
          machine-readable format.&nbsp;&nbsp;
        </li>
        <li>
          <strong>Right to Object. </strong>You have the right to object to the
          processing of your personal information under certain
          circumstances.&nbsp; Examples may include: (i) where the personal
          information is being processed on grounds relating to your personal
          situation, where the legal grounds for processing such personal
          information falls under categories (v) and (vi) as described in the
          sub-section titled “Legal basis for processing information” and (ii)
          where the personal information is processed for direct marketing
          purposes.
        </li>
        <li>
          <strong>Right to Lodge a Complaint.</strong>&nbsp; You have the right
          to lodge a complaint with a supervisory authority. For more
          information, please contact your local data protection authority.
        </li>
        <li>
          <strong>Right to Be Informed About International Transfers</strong>
          .&nbsp; You have the right to be informed about the international
          transfer of your personal information and safeguards in place.&nbsp;
          Please read the section of this Privacy Policy titled “International
          Data, Transfers and Processing”.
        </li>
        <li>
          <strong>Right To Object to Profiling.</strong>&nbsp; We may use
          automated decision-making in operating our website.&nbsp; You have the
          right not to be subjected to any decisions arising from automated
          processing, including profiling, which produces legal effects
          concerning you or similarly significantly affects you.&nbsp;
        </li>
      </Typography>
      <Typography>
        To exercise any of your rights, please contact us using the contact
        details provided below.&nbsp; We are required to verify the identity of
        anyone who makes any request.
      </Typography>
      <Typography>
        <strong>NOTICE TO CALIFORNIA RESIDENTS (CCPA NOTICE)</strong>
      </Typography>
      <Typography>
        The information in this section, under the subheading “Notice to
        California Residents”, applies to residents of California.&nbsp;
        References to “you” and “your” in this section refer only to residents
        of California.&nbsp; The California Consumer Protection Act (“CCPA”),
        which took effect on January 1, 2020, provides privacy rights for
        residents of California.&nbsp; Please refer to the referenced sections
        in this Privacy Policy for details on the following, which you have a
        right to know under the CCPA:
      </Typography>
      <Typography>
        <li>
          To review information on the categories of personal information and
          the categories of sources from which we collect, use, store and share
          personal information, read the section of this Privacy Policy titled
          “Information We Collect and How We Collect It”.&nbsp;
        </li>
        <li>
          To review information on the business or commercial purposes for which
          we collect information, read the section of this Privacy Policy titled
          “Purposes for Collecting Information”.
        </li>
        <li>
          To review information on the categories of third parties we share
          personal information, read the section of this Privacy Policy titled
          “Third Parties We Share Information With”.
        </li>
      </Typography>
      <Typography>
        As a California resident, you have certain rights you can exercise under
        the CCPA, including the following.&nbsp; Please note that this summary
        is merely provided for your convenience, but we do not warrant the
        accuracy or exhaustiveness of this summary nor should you rely on this
        as an accurate or exhaustive list of your rights.
      </Typography>
      <Typography>
        <li>
          <strong>Right to Know.&nbsp; </strong>You have the right to obtain
          from us certain information about our collection of your personal
          information over the past 12 month period, including: (i) the
          categories of personal information we collected, (ii) the specific
          pieces of your personal information we collected, (iii) the categories
          of sources for the personal information we collected, (iv) our
          business or commercial purpose for collecting or selling that personal
          information, (v) the categories of third parties with whom we share
          that personal information, and (vi) if we sold or disclosed your
          personal information, then (a) a list disclosing the categories of
          personal information involved in sales and the categories of third
          parties to whom we sold your personal information and (b) a list
          disclosing categories of personal information disclosed for a business
          purpose and the categories of third parties with whom we shared your
          personal information.&nbsp; You have the right to obtain this
          information from us, free of charge, twice each year.
        </li>
        <li>
          <strong>Right to Delete.</strong>&nbsp; You have the right to request
          that we delete your personal information that we have collected,
          subject to certain exceptions.&nbsp; When you contact us, please let
          us know the information you are requesting we remove, update, correct
          or amend, and the timeframe and manner in which you believe we came to
          collect such information.&nbsp;&nbsp;
        </li>
        <li>
          <strong>Right to Opt-Out.</strong>&nbsp; You have the right to opt-out
          of the sale of your personal information.&nbsp; We do not sell, rent
          or trade your personal information to any third parties, as we value
          your privacy.&nbsp; Therefore, no request to opt-out of sale is
          necessary.
        </li>
        <li>
          <strong>Right to Non-Discrimination.</strong>&nbsp; You have the right
          not to receive discriminatory treatment by us for exercising your
          rights under the CCPA.&nbsp; We will not discriminate against you in
          any way for exercising any of the rights available to you under the
          CCPA, including by denying you goods or services, charging you
          different prices or rates, providing you with a lesser quality of
          goods or services or suggesting that you would receive a different
          price or rate for goods or services or a different quality of goods or
          services.&nbsp;
        </li>
      </Typography>
      <Typography>
        To exercise any of your rights, please contact us using the contact
        details provided below.&nbsp; We are required to verify the identity of
        anyone who makes any request.
      </Typography>
      <Typography>
        <strong>
          NOTICE TO CALIFORNIA RESIDENTS (California’s “Shine the Light” Law)
        </strong>
      </Typography>
      <Typography>
        We do not share your personal information with third parties who we know
        or have reason to know may use your personal information for “direct
        marketing purposes” as contemplated by California’s “Shine the Light”
        law (Civil Code Section § 1798.83).
      </Typography>
      <Typography>
        <strong>NOTICE TO NEVADA RESIDENTS</strong>
      </Typography>
      <Typography>
        We do not exchange your personal information for monetary consideration
        with anyone who will license or sell your personal information to third
        parties.
      </Typography>
      <Typography>
        <strong>CHILDREN’S PRIVACY</strong>
      </Typography>
      <Typography>
        This website is not directed towards or designed for use by children
        under the age of 18.&nbsp; This website and the information contained on
        it is specifically designed for individuals over the age of 18.&nbsp; If
        you are under the age of 18, you must not access this website or perform
        any of the Actions.&nbsp; We do not knowingly collect, use, store or
        share personal information from children under the age of 18.&nbsp; If
        you know or have reason to believe that we have collected data from
        anyone under the age of 18, please contact us using the contact details
        provided for removal of that data.
      </Typography>
      <Typography>
        <strong>CHANGES TO THE PRIVACY POLICY</strong>
      </Typography>
      <Typography>
        We reserve the right to change, amend or modify this Privacy Policy at
        any time.&nbsp; We will alert you to any changes by posting the
        effective date of the latest version at the top of this page, at which
        point any changes will become immediately effective.&nbsp; It is your
        responsibility to check for updates, as your continued use of the
        website and our Products after the Privacy Policy is amended will
        constitute your acceptance and agreement to continue to be bound by the
        Privacy Policy as of the last updated date indicated at the top of this
        page.
      </Typography>
      <Typography>
        <strong>ALTERNATIVE FORMS</strong>
      </Typography>
      <Typography>
        You may print this Privacy Policy by using the “Print” function on your
        browser.
      </Typography>
      <div className="p-5"></div>
      <Typography variant="h1">Terms and Conditions</Typography>
      <Typography>
        <em>
          NOTICE: Please read the terms and conditions set forth below, which
          are legally binding.&nbsp; By visiting, viewing or using this website
          and/or by using any program, product, course or service from us, you
          agree to be bound by these Terms and Conditions and our Privacy Policy
          and Disclaimer.
        </em>
      </Typography>
      <Typography>
        PLEASE READ THE SECTIONS TITLED “BINDING ARBITRATION” AND “CLASS ACTION
        WAIVER” AS THEY AFFECT YOUR LEGAL RIGHTS.
      </Typography>
      <Typography>
        Moneycessity, https://moneycessity.com (“website”), which is operated by
        Pome LLC (“Company”, “we”, “us”, or “our”) provides visitors information
        on the website subject to the following terms and conditions (“Terms and
        Conditions”).&nbsp; The term “you” refers to any visitor, viewer or user
        of the website and/or any user of any free or paid program, product,
        course or service of the Company (each, a “Product”).
      </Typography>
      <Typography>
        By viewing, visiting or using the website and/or a Product, you indicate
        your acceptance and agreement to be bound by these Terms and Conditions
        and our Privacy Policy and Disclaimer, which are hereby incorporated by
        reference (collectively, this “Agreement”).&nbsp; If you do not accept
        the terms and conditions of this Agreement, then please do not use the
        website or any Products.&nbsp; These Terms and Conditions were created
        with the help of the Plug and Law and Privacy Policy Solutions.
      </Typography>
      <Typography>
        We reserve the right to amend this Agreement at any time without notice
        to you.&nbsp; We will alert you to any changes by posting the effective
        date of the latest version at the top of this page, at which point any
        changes will become immediately effective.&nbsp; It is your
        responsibility to check for updates, as your continued use of the
        website or any Products after this Agreement is amended will constitute
        your acceptance and agreement to continue to be bound by this Agreement,
        as amended.
      </Typography>
      <Typography>
        <strong>The United States AND OVER 18 USE ONLY</strong>
      </Typography>
      <Typography>
        The website is intended only for individuals over the age of 18 residing
        in the United States.&nbsp; We do not make any representations that this
        website is appropriate or available for use outside of the United
        States.&nbsp; If you access the website or any of our Products from
        outside of the United States, you do so at your own risk and on your own
        initiative.&nbsp; It is solely your responsibility to ensure compliance
        with applicable laws in your specific jurisdiction.
      </Typography>
      <Typography>
        <strong>GUIDELINES FOR USE</strong>
      </Typography>
      <Typography>
        We have established certain guidelines to keep our community safe
        (“Guidelines”).&nbsp; By visiting or using the website or any Product,
        you agree to abide by these Guidelines, which are as follows:
      </Typography>
      <Typography>
        <li>You will comply with all applicable law;</li>
        <li>
          You will not upload, post, send, email, or otherwise make available
          any information or content which in any way infringes any copyright,
          trade secret, trademark, right of publicity, privacy, property or
          other intellectual property or proprietary rights, or any information
          or content which you do not have the right to make available, through
          any law, contractual or fiduciary relationship or otherwise;
        </li>
        <li>
          You will not act in any way that is fraudulent, false, misleading,
          deceitful or deceptive, such as by impersonating another individual or
          falsifying your association with an individual or entity;
        </li>
        <li>
          You will not upload, post, send, email, or otherwise make available
          any material or behave in any manner which could be perceived as
          harassing, demeaning, threatening, “stalking”, defamatory, sexually
          explicit, abusive, vulgar, hate speech, obscene, indecent or otherwise
          objectionable;
        </li>
        <li>
          You will not upload, post, send, email, or otherwise make available
          any material which would reveal the personal information of another
          individual;
        </li>
        <li>
          You will not behave in any manner which could limit or otherwise
          impact any other person’s use or enjoyment of the website and/or any
          Product;
        </li>
        <li>
          You will not engage in any unsolicited or unauthorized advertising nor
          will you send any spam;
        </li>
        <li>
          You will not attempt to gain unauthorized access to any portion of the
          website or any of the Products;
        </li>
        <li>
          You will not engage in or encourage others to engage in any activity
          which would violate any law, constitute a criminal offense, give rise
          to civil liability, or infringe on the rights of any third party;
        </li>
        <li>
          You will not send any materials which contain viruses, devices,
          information collection or transmission mechanisms, trojan horses,
          worms, time-bombs, adware, keystroke loggers or any other programs or
          code which would be harmful to, interfere with or attempt to interfere
          with our systems;
        </li>
        <li>
          You will not engage in market research or any research intended to
          help a competitor;&nbsp;
        </li>
        <li>
          You will not deploy any automated query program, such as a bot or
          spider, at any time or for any purpose without our express written
          consent;&nbsp;
        </li>
        <li>
          You will not block or cover any advertisements on the website;&nbsp;
        </li>
        <li>
          With the exception of any personal information you share (which is
          covered under our Privacy Policy), once you upload, post, send, email,
          or otherwise make available any material, we have the right to
          display, repurpose or otherwise use such material in any way; and
        </li>
        <li>
          You will notify us through the contact information provided below if
          you know or have reason to know that a violation of any of our
          Guidelines has occurred.
        </li>
      </Typography>
      <Typography>
        We reserve the right to deny you access to the website and any Products
        in our sole discretion at any time and for any reason.
      </Typography>
      <Typography>
        <strong>INTELLECTUAL PROPERTY</strong>
      </Typography>
      <Typography>
        The website and its content and all Products, including but not limited
        to videos, coursework, training modules, photographs, sound recordings,
        images, digital content, material available as a free download,
        software, text, graphics and other material, are owned or licensed by
        the Company and are protected by copyright, trademarks (whether
        registered or unregistered), design rights, database rights and all
        other intellectual property rights afforded to us (“Intellectual
        Property”).
      </Typography>
      <Typography>
        While you may view and have access to our Intellectual Property for your
        own personal and non-commercial use, you agree to abide by the
        following:
      </Typography>
      <Typography>
        <li>
          Our Intellectual Property must be kept intact with the proper
          copyright and other intellectual property notices; and
        </li>
        <li>
          You may not reproduce, resell, distribute, publicly perform, create
          derivative works, translate, transmit, post, republish, exploit, copy
          or otherwise use our Intellectual Property for any commercial or
          non-personal use, unless you have received explicit written consent
          from us to do so.
        </li>
      </Typography>
      <Typography>
        It is in our sole discretion to give written consent for you to
        reproduce, resell, distribute, publicly perform, create derivative
        works, translate, transmit, post, republish, copy or otherwise use our
        Intellectual Property.&nbsp; If you have any questions, please contact
        us using the contact information provided below.
      </Typography>
      <Typography>
        <strong>NO WARRANTIES</strong>
      </Typography>
      <Typography>
        Your use of this website and any Products is entirely at your risk, as
        the website and our Products are provided on an “as is” and “as
        available” basis.&nbsp; We do not make any express or implied warranties
        or representations relating to the website, its content and our
        Products, including but not limited to warranties of title,
        merchantability, fitness for a particular purpose and non-infringement
        of third parties’ rights.&nbsp; We also do not make any express or
        implied warranties or representations that the website will operate
        without error or that the website, the servers relied on, our Products
        and any content is free from computer viruses or other potentially
        harmful or destructive features.&nbsp; Some jurisdictions do not permit
        the exclusion of certain warranties.&nbsp; As such, some of the
        exclusions referenced in this section may not apply to you.
      </Typography>
      <Typography>
        <strong>LIMITATION OF LIABILITY</strong>
      </Typography>
      <Typography>
        To the fullest extent permissible by law, the Company and our directors,
        contractors, successors, joint venture partners, shareholders, agents,
        affiliates, officers, employees, assignees and licensees, as applicable,
        shall not be liable for any direct, indirect, special, incidental,
        consequential, exemplary or other loss or damage, including but not
        limited to damages for loss of profits, goodwill, business interruption,
        use or loss of data or other intangible losses, which may directly or
        indirectly arise out of or be related to your use of or inability to
        access this website or any Products or your reliance on any advice,
        opinion, information, representation or omission contained on, or
        received through this website or any Products, even if we have been
        advised of the possibility of such damages occurring.&nbsp;&nbsp;
      </Typography>
      <Typography>
        This limitation of liability applies whether such liability arises from
        tort, negligence, breach of contract or any other legal theory of
        liability.
      </Typography>
      <Typography>
        <strong>INDEMNIFICATION</strong>
      </Typography>
      <Typography>
        You shall indemnify, defend and hold harmless the Company and our
        affiliates, and our respective directors, contractors, successors, joint
        venture partners, shareholders, agents, affiliates, officers, employees,
        assignees and licensees from and against any and all damages,
        liabilities, losses, costs and expenses resulting from any suits,
        proceedings, judgments, demands, causes of action and claims
        (collectively, the “Claims”), including legal and accounting fees and
        expenses, whether or not involving a third party claim, to the extent
        arising out of, relating to or resulting from: (i) your use or misuse of
        this website or any of our Products, (ii) your breach of this Agreement,
        or (iii) your violation of any third party rights, including without
        limitation any copyright, trade secret, trademark, right of publicity,
        privacy, property or other intellectual property or proprietary
        rights.&nbsp; We will provide you with notice of any Claims, and may in
        our sole discretion assist you, at your expense, in defending such
        Claims.&nbsp; We reserve the right to assume exclusive control of
        defending any such Claim, at your expense, including choice of legal
        counsel.&nbsp; You agree to cooperate and assist us in defending any
        such Claim.
      </Typography>
      <Typography>
        <strong>LIMITED LICENSE</strong>
      </Typography>
      <Typography>
        Notwithstanding any other provisions in this Agreement, if you purchase
        any Products or download any of our free Products, we grant you a
        limited, non-sublicensable, non-transferable, non-exclusive, revocable
        license (“License”) to use or access the Products for your personal and
        non-commercial use.&nbsp; You may not reproduce, resell, distribute,
        create derivative works, translate, transmit, post, republish, exploit,
        copy or otherwise use our Products for any commercial or non-personal
        use.&nbsp;&nbsp;
      </Typography>
      <Typography>
        Your License is for individual use.&nbsp; You have no right to assign
        any of your rights or transfer any of your obligations under this
        Agreement. &nbsp; If we discover that any violation of the terms and
        conditions in this Agreement has occurred, including violation of the
        License granted to you, we reserve the right to terminate your access to
        the Products and invoice you for any damages.
      </Typography>
      <Typography>
        <strong>CONFIDENTIAL INFORMATION</strong>
      </Typography>
      <Typography>
        Please do not upload, post, send, email, or otherwise make available any
        material that contains any confidential information.
      </Typography>
      <Typography>
        <strong>FEES</strong>
      </Typography>
      <Typography>
        When you purchase any Product through this website, you are responsible
        for all applicable fees and taxes.&nbsp; It is your responsibility to
        provide complete, accurate and up-to-date billing and credit card
        information.&nbsp; If you are on a payment plan or recurring payment, it
        is your responsibility to keep an up-to-date payment method with
        us.&nbsp; If your payment method is declined or expired, you hereby
        agree to pay all applicable fees and taxes due upon demand.&nbsp; You
        also agree to pay all costs of collection, including but not limited to
        attorney’s fees, on any outstanding balance.
      </Typography>
      <Typography>
        <strong>WEBSITE AVAILABILITY</strong>
      </Typography>
      <Typography>
        Your access to the website or Products may be occasionally restricted,
        such as when we need to make repairs or are introducing new
        features.&nbsp; Your access to the website or Products may also become
        permanently disabled, such as if we decide to terminate the operation of
        the website or Products.&nbsp; We cannot guarantee that you will have
        continuous access to the website or Products.
      </Typography>
      <Typography>
        <strong>REFUND POLICY</strong>
      </Typography>
      <Typography>
        We reserve the right to change our refund policy at any time.&nbsp; We
        offer refunds for all of our Products.&nbsp; If you are not satisfied
        with a Product, please contact us within 30 days of your purchase and we
        will gladly issue you a full refund.
      </Typography>
      <Typography>
        <strong>GOVERNING LAW</strong>
      </Typography>
      <Typography>
        All matters relating to or arising out of this Agreement shall be
        governed by and construed and interpreted under the laws of the State of
        Texas, United States of America, without regard to conflicts of laws
        principles that would require the application of any other law.&nbsp;
      </Typography>
      <Typography>
        <strong>BINDING ARBITRATION</strong>
      </Typography>
      <Typography>
        In the event that either party asserts that there is a dispute arising
        out of or relating to this Agreement, such party shall first notify the
        other party in writing, specifying the nature of the dispute and
        scheduling a meeting to attempt to resolve the dispute.&nbsp;&nbsp;
      </Typography>
      <Typography>
        If no resolution is reached within thirty (30) calendar days of the
        delivery of the written notice, either party may then elect to
        exclusively and finally resolve the dispute by binding arbitration by
        filing a written notice of arbitration in accordance with this section
        under the subheading “Binding Arbitration” and the Rules of Conciliation
        and Arbitration of the International Chamber of Commerce applicable at
        the time of submission of the dispute to arbitration (the “ICC
        Rules”).&nbsp;&nbsp;
      </Typography>
      <Typography>
        Notwithstanding anything to the contrary in the ICC Rules, the following
        terms and conditions shall apply.
      </Typography>
      <Typography>
        The arbitration shall be conducted by a panel of three (3)
        arbitrators.&nbsp; Each party shall have the right to appoint one (1)
        arbitrator within fifteen (15) days following the delivery of the
        written notice of arbitration, and a third arbitrator shall be selected
        by those two (2) party-appointed arbitrators within thirty (30) days of
        the appointment of the two (2) party-appointed arbitrators.
      </Typography>
      <Typography>
        The arbitration proceedings shall be held in Fort Worth, Texas, United
        States of America in the English language.
      </Typography>
      <Typography>
        The parties shall equally bear the costs and fees of the arbitration,
        and each party shall bear its own cost for its own legal expenses.&nbsp;
        The arbitrators shall apply the substantive law set forth in the section
        of this Agreement under the subheading “Governing Law”.&nbsp; Any
        arbitration proceeding shall be conducted on a confidential basis.
      </Typography>
      <Typography>
        The arbitrators shall specify the reason and basis for their decision,
        the reason for any damages awarded and the breakdown for such damages
        awarded, and the basis for any other remedy authorized under this
        Agreement, including but not limited to injunctive relief or specific
        performance.&nbsp; For the avoidance of doubt, nothing in this Agreement
        shall prevent us from seeking injunctive or other equitable
        relief.&nbsp;
      </Typography>
      <Typography>
        The decision of the arbitrators shall be considered as a final and
        binding resolution of the dispute, shall be final and binding on the
        parties, and shall not be subject to appeal or reexamination.&nbsp; The
        award of the arbitrators may be entered as a judgment and enforced by
        any court of competent jurisdiction.
      </Typography>
      <Typography>
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO WAIVE YOUR RIGHT
        TO TRIAL BY JURY IN ANY PROCEEDINGS, ACTION OR COUNTERCLAIM.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>INJUNCTIVE OR OTHER EQUITABLE RELIEF</strong>
      </Typography>
      <Typography>
        We may seek injunctive or other equitable relief that is necessary to
        protect our rights and intellectual property.&nbsp; All actions or
        proceedings related to this Agreement that are not subject to binding
        arbitration will be brought solely the laws of the State of Texas,
        United States of America.&nbsp; You hereby unconditionally and
        irrevocably consent to the personal and subject matter jurisdiction of
        those courts for purposes of any such action.
      </Typography>
      <Typography>
        <strong>CLASS ACTION WAIVER</strong>
      </Typography>
      <Typography>
        You agree that any dispute arising out of or relating to this Agreement
        shall be solely between you and the Company.
      </Typography>
      <Typography>
        YOU AGREE TO WAIVE THE RIGHT TO LITIGATE IN COURT OR ARBITRATE ANY CLAIM
        OR DISPUTE AS A CLASS ACTION.&nbsp; YOU AGREE THAT YOU WILL ONLY MAKE
        CLAIMS AGAINST US IN YOUR INDIVIDUAL CAPACITY, AND CANNOT MAKE CLAIMS
        AGAINST US AS A MEMBER OF A CLASS OR AS A REPRESENTATIVE.
      </Typography>
      <Typography>
        <strong>ENTIRE AGREEMENT</strong>
      </Typography>
      <Typography>
        This Agreement, the Privacy Policy and Disclaimer contain the entire
        agreement between you and the Company with respect to the subject matter
        hereof and thereof and supersede all prior agreements and undertakings,
        both written and oral, with respect thereto.
      </Typography>
      <Typography>
        <strong>TERMINATION OF AGREEMENT</strong>
      </Typography>
      <Typography>
        We reserve the right, in our sole discretion, to terminate this
        Agreement and to terminate, restrict, deny, or suspend your access to
        the website and all Products at any time and for any purpose without
        prior notice.&nbsp; We also reserve the right to discontinue any or all
        of the website or Products at any time and for any purpose without prior
        notice.
      </Typography>
      <Typography>
        <strong>SEVERABILITY</strong>
      </Typography>
      <Typography>
        If any term or other provision of this Agreement is held to be invalid,
        prohibited or unenforceable under applicable law, the other provisions
        of this Agreement will remain in full force and effect.&nbsp;
      </Typography>
      <Typography>
        <strong>MISCELLANEOUS</strong>
      </Typography>
      <Typography>
        Our failure to act on or delay in exercising any privilege, power or
        right under this Agreement will not operate as a waiver of such
        privilege, power or right, and no single or partial exercise of any such
        privilege, power or right will preclude any other or further exercise of
        such privilege, power or right or the exercise of any other privilege,
        power or right.&nbsp;
      </Typography>
      <Typography>
        Subheadings in this Agreement are used for convenience of reference only
        and in no way define, describe, limit or extend the scope of this
        Agreement or the intent of any of its provisions.&nbsp; They shall not
        be considered in construing or interpreting this Agreement.
      </Typography>
      <Typography>
        The terms and conditions of this Agreement shall inure to the benefit of
        and be binding upon the successors, heirs, executors, administrators,
        legal representatives and assigns of the Company.&nbsp; Nothing in this
        Agreement, express or implied, is intended to confer upon any party
        other than the Company or our successors, heirs, executors,
        administrators, legal representatives and assigns, any rights, remedies,
        obligations or liabilities under this Agreement.&nbsp;&nbsp;
      </Typography>
      <Typography>
        You may not assign any of your rights or transfer any obligations under
        this Agreement to any other person.
      </Typography>
      <Typography>
        If any action at law or in equity is necessary to enforce or interpret
        the terms of this Agreement, the prevailing party shall be entitled to
        reasonable attorneys’ fees, costs and disbursements in addition to any
        other relief to which such party may be entitled.
      </Typography>
      <div className="p-5"></div>
      <Typography variant="h1">Disclaimer</Typography>
      <Typography>
        <em>
          NOTICE: Please read the Disclaimer set forth below, which is legally
          binding.&nbsp; By visiting, viewing or using this website and/or by
          using any program, product, course or service from us, you agree to be
          bound by this Disclaimer and our Privacy Policy and Terms and
          Conditions.
        </em>
      </Typography>
      <Typography>
        Moneycessity, https://moneycessity.com (“website”), which is operated by
        Pome LLC (“Company”, “we”, “us”, or “our”) provides visitors information
        on the website as a public service, subject to the following terms and
        conditions (“Disclaimer”).&nbsp; The term “you” refers to any visitor,
        viewer or user of the website and/or any user of any free or paid
        program, product, course or service of the Company (each, a “Product”).
      </Typography>
      <Typography>
        <strong>GENERAL DISCLAIMER</strong>
      </Typography>
      <Typography>
        The content on this website is provided to you “as is” and is intended
        to serve as general information.&nbsp; While we strive to provide you
        with quality content, we give no representation or warranty that the
        content is accurate, complete, updated, timely, relevant or free from
        typographical, technical, informational or pricing errors and omissions,
        whether negligent or otherwise.&nbsp; By using this website or any
        Products, you agree and acknowledge that your use of this website and
        use of any Products is solely at your own risk.&nbsp; This Disclaimer
        was created with the help of Plug and Law and Privacy Policy Solutions.
      </Typography>
      <Typography>
        <strong>NOT PROFESSIONAL ADVICE</strong>
      </Typography>
      <Typography>
        This website does not contain professional advice, nor is any
        professional-client relationship established with you through your use
        of this website.&nbsp; Any information found on or derived from this
        website should not be a substitute for and cannot be relied on as any
        legal, tax, real estate, medical, financial, risk management, marital or
        other professional advice.&nbsp; If you require any such advice, please
        consult with a licensed or knowledgeable professional in that area
        before taking any action.
      </Typography>
      <Typography>
        <strong>YOUR RESPONSIBILITY</strong>
      </Typography>
      <Typography>
        It is your responsibility to take all necessary steps to independently
        verify and ascertain that any information you choose to rely on from,
        access through or take action based upon this website or our Products is
        accurate, as we are not responsible for your use of the information
        obtained from or accessed through this website or our Products.&nbsp;
        Any views expressed on this website are solely the personal views of the
        author and do not necessarily reflect the views of the
        Company.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>EARNINGS DISCLAIMER</strong>
      </Typography>
      <Typography>
        While we may, on this website, through any of our Products or in our
        communications, reference certain results or outcomes, you agree and
        acknowledge that information about these results or outcomes are
        received from third parties and we have no control over the accuracy of
        such statements, nor is there any guarantee that you can achieve the
        same results or outcomes.&nbsp; You agree and acknowledge that these
        results are not guaranteed or typical in any way and that individual
        outcomes may vary.&nbsp; Please do not rely on these results or outcomes
        in using the website or purchasing any Products.
      </Typography>
      <Typography>
        <strong>THIRD PARTY LINKS</strong>
      </Typography>
      <Typography>
        Our website may contain links to third party websites, for example,
        through hyperlinks we provide or through banners or advertisements,
        solely as a convenience to you. However, we are not responsible for any
        content found on or accessed through any links to third party
        websites.&nbsp; Any links to third party websites we provide do not
        serve as endorsements by us for the third party website or any of the
        products or services you may find on such website.&nbsp; We have no
        control over third parties and assume no responsibility for any third
        party websites or any of the products or services you may find on such
        websites, even if you access a third party website through a link on
        this website.&nbsp; If you choose to access a third party website, it is
        solely at your own risk.
      </Typography>
      <Typography>
        <strong>TESTIMONIALS DISCLAIMER</strong>
      </Typography>
      <Typography>
        This website may contain testimonials by users of our Products.&nbsp;
        Each testimonial reflects solely the personal view, opinion or
        experience of the individual providing the testimonial and does not
        reflect our views or opinions.&nbsp; You should not rely on any
        testimonial as indicative of a certain result or outcome.&nbsp; We do
        not claim, nor should you assume that your use of our Products will lead
        to the same result or outcome.&nbsp; We also do not independently
        verify, nor can we guarantee the accuracy of any information provided in
        such testimonials.&nbsp;&nbsp;
      </Typography>
      <Typography>
        Except for correcting spelling and grammatical errors, each testimonial
        appears verbatim as we have received it.&nbsp; We do not pay or provide
        any form of compensation to individuals for providing
        testimonials.&nbsp;&nbsp;
      </Typography>
      <Typography>
        <strong>AFFILIATE DISCLAIMER</strong>
      </Typography>
      <Typography>
        This website may contain links to affiliate websites.&nbsp; When you
        click on and/or make a purchase through an affiliate link placed on our
        website, we may receive a small commission or other form of compensation
        at no additional cost to you.&nbsp; Please assume that any links
        contained on our website are affiliate links.&nbsp; Our use of affiliate
        links does not influence the products, services and websites that we
        share with you.&nbsp; This Disclaimer applies to all of the various
        means we use to communicate with you, including via this website, email,
        phone, social media, our Products or otherwise.&nbsp;
      </Typography>
      <Typography>
        <strong>AMAZON AFFILIATE DISCLAIMER</strong>
      </Typography>
      <Typography>
        We are a participant in the Amazon Services LLC Associates Program, an
        affiliate advertising program designed to provide a means for us to earn
        fees by linking to Amazon.com and affiliated sites.&nbsp; When you click
        on and/or make a purchase through a link to an Amazon affiliate link
        placed on our website, we may receive a small commission at no
        additional cost to you.
      </Typography>
      <Typography>
        <strong>SPONSORED POSTS</strong>
      </Typography>
      <Typography>
        This website may contain sponsored posts or reviews, where we receive a
        form of compensation in exchange for publishing a post or a review of a
        product or service.&nbsp; We may also receive a small commission or
        other form of compensation at no additional cost to you if you click on
        and/or make a purchase through an affiliate link in a sponsored post or
        review.
      </Typography>
      <Typography>
        <strong>FAIR USE DISCLAIMER</strong>
      </Typography>
      <Typography>
        We may use copyrighted material on our website without specific
        authorization.&nbsp; In these instances, we do so because we believe
        such use constitutes fair use of any such copyrighted material under
        Section 107 of the United States copyright law.
      </Typography>
      <Typography>
        <strong>CHANGES TO THE DISCLAIMER</strong>
      </Typography>
      <Typography>
        We reserve the right to amend this Disclaimer at any time without notice
        to you.&nbsp; We will alert you to any changes by posting the effective
        date of the latest version at the top of this page, at which point any
        changes will become immediately effective.&nbsp; It is your
        responsibility to check for updates, as your continued use of the
        website and our Products after this Disclaimer is amended will
        constitute your acceptance and agreement to continue to be bound by this
        Disclaimer, as amended.
      </Typography>
      <Typography>
        <strong>HOW TO CONTACT US</strong>
      </Typography>
      <Typography>
        If you have any questions, please contact us by email:
        natalie@moneycessity.com.
      </Typography>
      <Typography>Updated 09.30.2023.</Typography>
    </div>
  );
};
