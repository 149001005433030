const BannerSection = () => {
  return (
    <section className="">
      <div className="flex justify-between">
        <h1 className="leading-none font-black text-white text-start text-[17vw] md:text-[17vw]">LIVE</h1>
        <h1 className="leading-none font-black text-white text-end text-[17vw] md:text-[17vw]">RICH</h1>
      </div>
      <div className="flex justify-between">
        <h3 className="leading-none font-black text-white text-start text-[10.5vw]">BUILD</h3>
        <h3 className="leading-none font-black text-white text-end text-[10.5vw]">WEALTH</h3>
      </div>
    </section>
  );
};

export default BannerSection;
