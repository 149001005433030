import { Typography } from "../../UI/Typography";
import BudgetImage from "../../../assets/spreadsheets/budget/Laptop.png";

export const BudgetSection = ({ togglePopup }) => {
  return (
    <section className="budget-container">
      <div className="flex flex-col md:flex-row items-center px-2 md:px-20">
        <div className="text-left w-4/6 order-2 md:order-1">
          <Typography variant="h1">3. Budget Spreadsheet</Typography>
          <Typography variant="h3">
            Simplify your budgeting and manage your money like a pro!
          </Typography>

          <div>
            <Typography variant="">
              - Automated fields for income and expenses—just fill in the
              blanks.
            </Typography>
            <Typography variant="">
              - Helps you track spending without the headache—budgeting made
              easy.
            </Typography>
            <Typography variant="">
              - Ready to boost your financial game, and yes, it's FREE!
            </Typography>
          </div>

          <button
            onClick={togglePopup}
            className="budget-download-button download-button hover-dark text-white font-extrabold bg-black px-6 py-4 hover:opacity-80 my-5"
          >
            DOWNLOAD NOW
          </button>
        </div>
        <div className="w-full md:w-2/6 order-1 md:order-2">
          <img src={BudgetImage} className="w-4/6 m-auto" />
        </div>
      </div>
    </section>
  );
};
