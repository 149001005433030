import MenuImage from "../../assets/header/menu_button.svg";

const MenuButton = ({toggleMenu}) => {
  return (
    <button onClick={toggleMenu} className="h-10 aspect-square rounded-full hover:bg-white hover:p-0.5 duration-150">
      <img src={MenuImage} className="" />
    </button>
  );
};

export default MenuButton;
