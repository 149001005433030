import { Typography } from "../../UI/Typography";
import StockImage from "../../../assets/spreadsheets/stock/Monitor.png";
import DecorImage from "../../../assets/spreadsheets/decor3.png";

export const StockSection = ({ togglePopup }) => {
  return (
    <section className="stock-container ">
      <div className="flex flex-col md:flex-row items-center px-2 md:px-20">
        <div className="w-full md:w-2/6 ">
          <img src={StockImage} className="w-4/6 m-auto" />
        </div>
        <div className="text-right lg:text-left w-9/12 md:w-4/6">
          <Typography variant="h1">
            2. Stock Intrinsic Value Calculator
          </Typography>
          <Typography variant="h3">
            Quickly find a company’s true worth with my Intrinsic Value
            Calculator.
          </Typography>

          <div>
            <Typography variant="">
              - Uses the same formula trusted by Warren Buffett and other
              legendary investors.
            </Typography>
            <Typography variant="">
              - Automated to save you time—just input the numbers and get the
              value.
            </Typography>
            <Typography variant="">
              - Built with 50+ hours of research, ready to use for FREE!
            </Typography>
          </div>

          <button
            onClick={togglePopup}
            className="stock-download-button download-button hover-dark text-white font-extrabold bg-black px-6 py-4 hover:opacity-80 my-5"
          >
            DOWNLOAD NOW
          </button>
        </div>
      </div>
      <img src={DecorImage} className="w-full py-5" />
    </section>
  );
};
