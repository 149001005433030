import React from "react";
import { Link } from "react-router-dom";

import MoneycessityLogo from "../../assets/header/MoneyCessityLogoBold.svg";

const LogoButton = () => {
  return (
    <Link to="/">
      <button className="flex-grow text-center ml-14 md:ml-0 md:text-left md:flex-grow-0">
        <img src={MoneycessityLogo} className="h-auto md:w-auto md:h-3/4 " />
      </button>
    </Link>
  );
};

export default LogoButton;
