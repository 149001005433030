import YoutubeIcon from "../../../assets/menu/media/youtube.svg";
import TiktokIcon from "../../../assets/menu/media/tiktok.svg";
import MediumIcon from "../../../assets/menu/media/medium.svg";
import ThreadsIcon from "../../../assets/menu/media/threads.svg";
import TwitterIcon from "../../../assets/menu/media/twitter.svg";

export const MediaSection = () => {
  const MediaItems = [
    { icon: YoutubeIcon, url: "https://linktw.in/ZseMUM" },
    { icon: TiktokIcon, url: "https://tiktok.com/@moneycessity" },
    { icon: MediumIcon, url: "https://medium.com/@moneycessity" },
    { icon: ThreadsIcon, url: "https://threads.net/@moneycessity" },
    { icon: TwitterIcon, url: "https://twitter.com/moneycessity" },
  ];

  return (
    <div className="media-section flex flex-row justify-between w-full">
      {MediaItems.map((item, i) => {
        return (
          <a key={i} href={item.url} className="w-12 rounded-full p-1 hover:bg-white">
            <img src={item.icon} className="" />
          </a>
        );
      })}
    </div>
  );
};
