import { Typography } from "../Typography";
import { MediaSection } from "./MediaSection";
import { NavSection } from "./NavSection";
import CloseImage from "../../../assets/menu/close.svg";

export const Menu = ({ toggleMenu, isMenuOpen }) => {
  const menuStyle = {
    transitionDuration: "0.5s",
    right: isMenuOpen ? "0" : "-60vw",
  };

  const backgroundStyle = {
    transitionDuration: "0.5s",
    opacity: isMenuOpen ? "1" : "0",
    visibility: isMenuOpen ? "visible" : "hidden",
  };

  return (
    <div
      style={backgroundStyle}
      className="menu-background fixed top-0 right-0 w-[100vw] h-[100vh] bg-dark-sec bg-opacity-25"
    >
      <div
        style={menuStyle}
        className="menu-container fixed h-full ml-auto bg-beige-prim flex flex-col items-end justify-between p-10 md:p-14 w-full md:w-6/12 xl:w-4/12 outline outline-0 md:outline-[3px] md:rounded-l-lg"
      >
        <div className="flex flex-col items-end justify-around w-full h-full">
          <button onClick={toggleMenu} className="close-button mr-auto">
            <img src={CloseImage} className="w-10" />
          </button>
          <NavSection toggleMenu={toggleMenu} />
          <MediaSection />
        </div>
        <div className="">
          <Typography>© 2024 Moneycessity.</Typography>
        </div>
      </div>
    </div>
  );
};
