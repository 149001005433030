import { useEffect, useState } from "react";
import {
  ContentSection,
  SpreadsheetSection,
  WelcomeSection,
} from "../components/layout/LandingPage";
import { listRecentPosts } from "../api";

export const LandingPage = () => {
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // get articles data
        const result = await listRecentPosts();
        setRecentPosts(result?.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
    // console.log(recentPosts);
  }, []);

  return (
    <div className="bg-beige-prim pt-0 md:pt-3">
      <WelcomeSection />
      <ContentSection recentPosts={recentPosts} />
      <SpreadsheetSection />
    </div>
  );
};
