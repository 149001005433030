import { useState } from "react";
import {
  BudgetSection,
  CreditSection,
  IntroSection,
  StockSection,
} from "../components/layout/SpreadsheetsPage";
import { PopUp } from "../components/UI/PopUp";

export const SpreadsheetsPage = () => {
  const [spreadsheetName, setSpreadsheetName] = useState("Default Spreadsheet");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupSubtitle, setPopupSubtitle] = useState("");
  const [isPopup, setIsPopup] = useState(true);

  const handleClick = () => {
    const element = document.getElementsByClassName("credit-container")[0];
    element?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const togglePopup = (event) => {
    const downloadButton = event.target;
    // console.log("downloadButton element: ", downloadButton);

    if (downloadButton.classList.contains("credit-download-button")) {
      setSpreadsheetName("Credit Card Spreadsheet");
    } else if (downloadButton.classList.contains("stock-download-button")) {
      setSpreadsheetName("Stock Intrinsic Value Calculator");
    } else if (downloadButton.classList.contains("budget-download-button")) {
      setSpreadsheetName("Budget Spreadsheet");
    }

    const element = document.getElementsByClassName("popup-background")[0];

    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  return (
    <div className="bg-beige-prim pt-20 flex flex-col gap-0 md:gap-10 lg:gap-20">
      <IntroSection handleClick={handleClick} />
      <CreditSection togglePopup={togglePopup} />
      <StockSection togglePopup={togglePopup} />
      <BudgetSection togglePopup={togglePopup} />
      <PopUp
        title={spreadsheetName}
        subtitle="By clicking ' I'm In ', you will get the spreadsheet for free and agree to subscribe to my email newsletter all about making more money and living a richer life."
        subtitle2="No pressure, you can unsubscribe anytime."
        togglePopup={togglePopup}
      />
    </div>
  );
};
