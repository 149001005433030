import React, { useEffect, useState } from "react";
import { Grid, Pagination, Box, PaginationItem } from "@mui/material";
import { ArticlesCard } from "./ArticlesCard";
import arrowPrev from "../../../assets/articles/archive/arrowPrev.svg";
import arrowNext from "../../../assets/articles/archive/arrowNext.svg";

const CustomPagination = ({ count, page, onChange }) => {
  return (
    <Pagination
      siblingCount={0}
      size="small"
      count={count}
      page={page}
      onChange={onChange}
      color="800"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          components={{
            previous: () => (
              <img src={arrowPrev} alt="left arrow" style={{ width: "50px" }} />
            ),
            next: () => (
              <img
                src={arrowNext}
                alt="right arrow"
                style={{ width: "50px" }}
              />
            ),
          }}
        />
      )}
    />
  );
};

export const PaginationSection = ({ data }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    setPage(1);
  }, [data]);

  // Calculate the number of pages based on the data length
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  // Get the current items for the current page
  const currentItems = data?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Handle page change
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 4 }}>
      {/* Grid for displaying the 3x3 card layout */}
      <Grid container spacing={2}>
        {currentItems?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ArticlesCard
              thumbnail_url={item.thumbnail_url}
              title={item.title}
              preview_text={item.preview_text}
              id={item.id}
            />
          </Grid>
        ))}
      </Grid>

      {/* Pagination component */}
      <Box mt={4} display="flex" justifyContent="center">
        <CustomPagination
          count={totalPages}
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};
