import { Typography } from "../../UI/Typography";
import StoryImage from "../../../assets/about/story/6378907.png";

const StoryContent = [
  "Hi, I’m Brian.",
  "When I got my first job, I quickly realized I wanted to make my money work for me. That’s when I discovered investing. I learned how compounding works and how investing can build wealth faster than just saving.",
  "But more than just making money, what I really wanted was freedom: the freedom to work on projects I care about, plan my days my way, and make choices without feeling tied down.",
  "I dived deep into learning. I listened to audiobooks, watched videos, took courses, and tuned in to podcasts — anything that could help me reach financial freedom. It wasn’t always easy; I tried different strategies, faced challenges, and rode the ups and downs. Through it all, I developed my own investing philosophy.",
  "Today, I’m thankful to be building the Moneycessity community. I know there are many people out there working hard to achieve financial freedom and feeling overwhelmed at times. That’s why I wanted to share my journey and what I’ve learned about growing wealth and building a healthy money mindset. I hope to spark meaningful conversations and connect with others who are passionate about investing. Because in the end, it’s not just about reaching the goal — it’s about enjoying the journey.",
  "Catch you on the flip side.",
];

export const StorySection = () => {
  return (
    <section className="story-section mt-20 flex flex-col items-center gap-10 px-3 md:px-16">
      <Typography variant="h1">My Story</Typography>
      <div className="text-left flex flex-col items-start gap-3 md:gap-5">
        {StoryContent.map((item, i) => {
          if (i < 3) return <Typography>{item}</Typography>;
        })}
        <img src={StoryImage} className="m-auto w-6/12 md:w-4/12 lg:w-3/12" />
        {StoryContent.map((item, i) => {
          if (i >= 3) return <Typography>{item}</Typography>;
        })}
      </div>
    </section>
  );
};
