import RecaptchaButton from "../RecaptchaButton";
import EmailImage from "../../assets/subscribe/mail.svg";
import { useState } from "react";
import { createSubscription } from "../../api";

const SubscribeForm = ({ content, aligns, popUpTitle }) => {
  const [subscriber, setSubscriber] = useState({});
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);

  // EMAIL VALIDATION
  const emailValidation = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    // // Validate email using the regex
    setIsValid(emailRegex.test(inputEmail));
  };

  // CREATE SUBSCRIBE
  const createSubscribe = async () => {
    try {
      const result = await createSubscription(email); // Fetch the data
      setSubscriber(result.data); // Set the posts data in state
      setIsSubscribe(true);
    } catch (error) {
      console.error("Error fetching posts:", error); // Handle error
    }
  };

  // HANDLE SUBSCRIPTION
  const handleSubscription = () => {
    // INPUT VALIDATION
    if (!isValid) {
      console.log("email invalid");
    } else {
      // TRY SUBSCRIBE
      createSubscribe();
      // console.log("Is Subscribe: ", isSubscribe);
      let url =
        "https://drive.google.com/uc?export=download&id=1DNqW5FDl3fKylDWXBApOKv67O8iNHGtR";
      // IF .. DOWNLOAD
      if (popUpTitle?.toUpperCase().includes("CREDIT")) {
        url = "https://drive.google.com/uc?export=download&id=1VWga1TJOONv5jipWjpNbehI525rCWjxi";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); // Optional: specify the downloaded file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // console.log("Credit Card Spreadsheet Downloading");
      } else if (popUpTitle?.toUpperCase().includes("STOCK")) {
        url = "https://drive.google.com/uc?export=download&id=1PFGWIOMUooNgR_2V3q73GyeObUhYCld7";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); // Optional: specify the downloaded file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // console.log("Stock Intrinsic Value Calculator Downloading");
      } else if (popUpTitle?.toUpperCase().includes("BUDGET")) {
        url = "https://drive.google.com/uc?export=download&id=1MKjoBSfTzUpswW43r5Hc7SEHuyngMH_K";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); // Optional: specify the downloaded file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // console.log("Budget Spreadsheet Downloading");
      }
    }
  };

  switch (aligns) {
    case "center":
      return (
        <section className="flex flex-col items-center gap-3 md:w-full">
          <div className="flex flex-row items-center justify-center md:w-full">
            <div className="w-7/12 relative md:h-18 md:w-3/5">
              <img
                src={EmailImage}
                className="absolute inset-y-0 left-2 top-5 w-6 "
              />
              <input
                className="border-black border-2 h-16 text-lg w-full p-2 pl-10 md:h-18 md:w-full"
                placeholder="Email"
                onChange={emailValidation}
                style={{
                  color: isValid ? "black" : "#FF3131",
                  fontStyle: isValid ? "" : "oblique 60deg",
                }}
              ></input>
            </div>

            <button
              onClick={handleSubscription}
              style={
                isSubscribe
                  ? { backgroundColor: "#D9EAD3", color: "#274E13" }
                  : { backgroundColor: "#918e88" }
              }
              className="w-5/12 uppercase border-dark-sec border-0 text-white h-16 px-3 py-1 font-extrabold text-center align-middle text-sm md:text-3xl"
            >
              {isSubscribe ? "YOU'RE IN ✔" : content}
            </button>
          </div>
          <RecaptchaButton />
        </section>
      );
    default:
      return (
        <section className="flex flex-col items-stretch gap-3 md:w-full">
          <div className="flex md:w-full">
            <div className="relative md:h-18 md:w-3/5">
              <img
                src={EmailImage}
                className="absolute inset-y-0 left-2 top-5 w-6 md:"
              />
              <input
                className="border-black border-2 h-16 text-lg w-full p-2 pl-10 md:h-18 md:w-full"
                placeholder="Email"
                onChange={emailValidation}
                style={{
                  color: isValid ? "black" : "#FF3131",
                  fontStyle: isValid ? "" : "oblique 60deg",
                }}
              ></input>
            </div>

            <button
              style={
                isSubscribe
                  ? { backgroundColor: "#D9EAD3", color: "#274E13" }
                  : { backgroundColor: "#918e88" }
              }
              className="uppercase border-dark-sec border-0 text-white h-16 px-3 py-1 font-extrabold text-center align-middle text-sm lg:text-3xl"
            >
              {isSubscribe ? "YOU'RE IN ✔" : content}
            </button>
          </div>
          <RecaptchaButton />
        </section>
      );
  }
};

export default SubscribeForm;
