import React, { useState } from "react";
import LogoButton from "./LogoButton";
// import AppButton from "./AppButton";
import SearchButton from "./SearchButton";
import MenuButton from "./MenuButton";
import { Menu } from "../UI/Menu";

const HeaderComponents = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    if (isMenuOpen == true) {
      setIsMenuOpen(false);
    } else if (isMenuOpen == false) {
      setIsMenuOpen(true);
    }
  };

  return (
    <header className="bg-beige-prim shadow-md w-full p-4 flex items-center justify-between z-30 md:justify-start md:px-20 fixed top-0 left-0">
      <LogoButton />
      {/* <AppButton /> */}
      <section className="flex gap-4 md:ml-auto">
        <SearchButton />
        <MenuButton toggleMenu={toggleMenu} />
      </section>
      <Menu toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
    </header>
  );
};

export default HeaderComponents;
