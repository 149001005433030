import { Route, Routes, useLocation } from "react-router-dom";
import {
  AboutPage,
  ArticlePage,
  ArticlesPage,
  DisclaimerPage,
  ErrorPage,
  LandingPage,
  SpreadsheetsPage,
} from "../pages";
import { useEffect } from "react";

const MoneycessityRoutes = ({ post, allPosts, postID, setPostID }) => {
  const location = useLocation();

  // DETECT POST ID FROM URL WHEN RE-ROUTE
  useEffect(() => {
    if (location.pathname.includes("/article/")) {
      // ISOLATE POST ID
      const id = location.pathname.split("article/");
      if (postID !== id) setPostID(id[1]);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about-me" element={<AboutPage />} />
      <Route path="/articles" element={<ArticlesPage allPosts={allPosts} />} />
      <Route path="/disclaimer" element={<DisclaimerPage />} />
      <Route path="/spreadsheets" element={<SpreadsheetsPage />} />
      <Route path="/article/:id" element={<ArticlePage post={post} />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default MoneycessityRoutes;
