import {
  ArchiveSection,
  IntroSection,
} from "../components/layout/ArticlesPage";

export const ArticlesPage = ({allPosts}) => {
  return (
    <div className="bg-beige-prim pt-20">
      <IntroSection />
      <ArchiveSection allPosts={allPosts} />
    </div>
  );
};
